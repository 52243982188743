<template>
  <div>
    <div class="take-orders" v-loading="loading">
      <img class="img-bg" src="../assets/images/painter/tabke-orders-bg.png" alt="" />
      <swiper
        class="swiper"
        :options="swiperOption"
        v-if="!loading && data.length > 0"
      >
        <swiper-slide v-for="item in data" :key="item.id">
          <div class="bootom" v-if="type == 'painter'">
            <div class="text">发布时间：{{ item.createtime_text }}</div>
            <div
              class="button top-center-img"
              style="padding-left: 1vw; padding-right: 1vw"
              v-if="type == 'painter'"
            >
              测试文案
            </div>
            <div class="text">状态：{{ item.state_text }}</div>
          </div>
          <div
            class="demand-img"
            v-for="(image, index) in item.images"
            :key="index"
            v-show="index == 0 && type == 'enterprise'"
          >
            <img :preview="item.id" :src="image" alt="" />
          </div>
          <div
            class="cneter-text"
            v-show="type == 'painter'"
            @click="showDetail(item)"
          >
            <div class="title">{{ item.name }}</div>
            <div class="content">
              <div class="item-title">
                一.画图文案如下：（请画师认真阅读文案，再创意）
              </div>
              <div class="item-text">{{ item.content }}</div>
              <div class="item-title">二.绘画风格参考</div>
              <div class="item-img">
                <div
                  class="item-img-div"
                  v-for="(img, index) in item.images"
                  :key="index"
                >
                  <img :preview="item.id" :src="img" alt="" />
                </div>
              </div>
              <div class="item-title">三.测试图尺寸</div>
              <div class="item-text">
                高{{ item.size_json.height }}*宽{{ item.size_json.width }}
                cm
              </div>
              <div class="item-title">四.测试时间</div>
              <div class="item-text">{{ item.time_text }} 截止</div>
              <div class="item-right-text">
                测试起始时间{{ item.createtime_text }}
              </div>
            </div>
          </div>
          <div
            class="button"
            v-if="type == 'enterprise'"
            @click="$emit('choose_painter', item)"
          >
            选他(她)
          </div>
          <div class="take-order" v-else-if="type == 'painter'">
            <img
              src="../assets/images/painter/take-order.png"
              alt="接单"
              @click="$emit('takeOrder', item)"
            />
          </div>
        </swiper-slide>
      </swiper>
      <div v-else class="swiper">
        <div class="no-data" v-if="!loading && data.length == 0">没有数据</div>
      </div>
      <div class="swiper-button swiper-button-prev" slot="button-prev">
        <img src="../assets/images/painter/arrow.png" alt="" />
      </div>
      <div class="swiper-button swiper-button-next" slot="button-next">
        <img src="../assets/images/painter/arrow.png" alt="" />
      </div>
    </div>
    <div class="show-detail-item" v-if="showDetailItem">
      <i class="el-icon-circle-close" @click="showDetailItem = false"></i>
      <div class="cneter-text">
        <div class="title">{{ showDetailData.name }}</div>
        <div class="content">
          <div class="item-title">
            一.画图文案如下：（请画师认真阅读文案，再创意）
          </div>
          <div class="item-text">{{ showDetailData.content }}</div>
          <div class="item-title">二.绘画风格参考</div>
          <div class="item-img">
            <div
              class="item-img-div"
              v-for="(img, index) in showDetailData.images"
              :key="index"
            >
              <img :preview="showDetailData.id" :src="img" alt="" />
            </div>
          </div>
          <div class="item-title">三.测试图尺寸</div>
          <div class="item-text">
            高{{ showDetailData.size_json.height }}*宽{{
              showDetailData.size_json.width
            }}
            cm
          </div>
          <div class="item-title">四.测试时间</div>
          <div class="item-text">{{ showDetailData.time_text }} 截止</div>
          <div class="item-right-text">
            测试起始时间{{ showDetailData.createtime_text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: Array,
    type: String,
    loading: Boolean,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      showDetailData: {},
      showDetailItem: false,
    };
  },
  methods: {
    showDetail(item) {
      console.log(item);
      this.showDetailData = item;
      this.showDetailItem = !this.showDetailItem;
    },
  },
};
</script>

<style lang="less" scoped>
.take-orders {
  position: relative;
  left: 5%;
  width: 85%;
  .img-bg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .no-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    left: 5%;
  }
  .swiper {
    width: 90%;
    height: 23vw;
    padding: 5% 0;
    .swiper-slide {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      background: url(../assets/images/painter/tabke-orders-img-bg2.png) no-repeat;
      background-size: 100% 100%;
      flex-direction: column;
      padding: 3% 1%;
      .button {
        background: url(../assets/images/painter/title-bg.png) no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: FZY4JW;
        font-weight: 800;
        color: #000000;
        padding: 3% 0;
        white-space: nowrap;
        cursor: pointer;
        width: 40%;
        margin: 0 auto;
      }
      .top-center-img {
        cursor: unset;
      }
      .take-order {
        width: 5vw;
        margin: 0 auto;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
      .demand-img {
        width: 100%;
        margin: 5% 0;
        height: 15vw;
        overflow: auto;
        display: flex;
        align-items: center;
        cursor: zoom-in;
        img {
          width: 100%;
        }
      }
      .bootom {
        display: flex;
        justify-content: space-between;
        font-size: 11px;
        color: #fff;
        text-align: center;
        align-items: center;
        .text {
          flex: 1 1 100%;
        }
      }
    }
  }
  .swiper-button {
    width: 8%;
    &::after {
      content: "";
    }
    img {
      width: 100%;
    }
  }
  .swiper-button-prev {
    left: -7%;
  }
  .swiper-button-next {
    right: -7%;
  }
  .swiper-button-next > img {
    transform: rotate(180deg);
  }
}

.cneter-text {
  height: 100%;
  background: #fff;
  height: 25vw;
  overflow: auto;
  margin: 0.5vw 0;
  padding: 1vw;
  .title {
    font-size: 1.2vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #222222;
    margin: 1vw 0;
  }
  .content {
    text-align: left;
    .item-title {
      font-size: 1.1vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #222222;
    }
    .item-img {
      display: flex;
      .item-img-div {
        width: 50%;
        img {
          width: 100%;
          cursor: zoom-in;
        }
      }
    }
    .item-text {
      font-size: 1vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #555555;
      padding: 1vw;
    }
    .item-right-text {
      text-align: right;
      font-size: 1vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #656565;
    }
  }
}
.show-detail-item {
  background: #fff;
  position: absolute;
  top: 15vw;
  left: 40%;
  z-index: 999;
  padding: 1vw;
  border-radius: 5px;
  width: 30vw;
  .el-icon-circle-close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  /deep/ img {
    cursor: auto !important;
  }
}
</style>
