<template>
  <div>
    <OrderSwiper
      :data="orderSwiperData"
      type="enterprise"
      :loading="loading"
      @choose_painter="choose_painter"
    ></OrderSwiper>
  </div>
</template>

<script>
import OrderSwiper from "../../components/OrderSwiper.vue";

export default {
  components: {
    OrderSwiper,
  },
  data() {
    return {
      loading: true,
      orderSwiperData: [],
    };
  },
  mounted() {
    this.getRecommendList();
  },
  methods: {
    // 获取推荐列表
    getRecommendList() {
      this.loading = true;
      this.$api.projectTestimg.recommend_list().then((res) => {
        res.data.forEach((item) => {
          item.images = item.image.split(",");
        });
        this.orderSwiperData = res.data;
        this.loading = false;
      });
    },
    // 选择测试图的画师
    choose_painter(projectTestimg) {
      let data = {
        project_id: projectTestimg.project_id,
        user_id: projectTestimg.user_id,
      };
      this.$api.project.choose_painter(data).then(() => {
        this.$router.push({
          name: "/enterprise/communication",
          params: { type: "centerTypeOfImg", data: projectTestimg },
        });
      });
    },
  },
};
</script>
